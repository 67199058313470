import { Text, View } from "@react-pdf/renderer";
import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import AccentLine from "@src/shared/vehicleDetail/pdf/accent/AccentLine";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import VehicleDataEntryComponent from "./VehicleDataEntryComponent";
import { vehicleDataStyles } from "./VehicleDataStyles";

interface IDataProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const VehicleDataComponent: React.FunctionComponent<IDataProps> = ({ vehicle }) => {
    const intl = useIntl();

    return (
        <View style={vehicleDataStyles.root}>
            <View>
                <AccentLine />
                <Text style={vehicleDataStyles.title}>
                    <FormattedMessage id="leaseme.vehicleData" defaultMessage="Fahrzeugdaten" />
                </Text>
            </View>
            <View style={vehicleDataStyles.content}>
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.color",
                        defaultMessage: "Farbe",
                    })}
                    value={vehicle.bodyColor ? vehicle.bodyColor.text : null}
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.firstRegistration",
                        defaultMessage: "Erstzulassung",
                    })}
                    value={vehicle.firstRegistration ? format(parseISO(vehicle.firstRegistration), "MM/yyyy") : null}
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.mileage",
                        defaultMessage: "Kilometerstand",
                    })}
                    value={
                        vehicle?.mileage
                            ? `${intl.formatNumber(vehicle.mileage)} ${intl.formatMessage({
                                  id: "leaseme.km",
                                  defaultMessage: "km",
                              })}${getConfig("scope").domain === "car4me.bg" ? "" : " *"}`
                            : `0 ${intl.formatNumber(0)} ${intl.formatMessage({
                                  id: "leaseme.km",
                                  defaultMessage: "km",
                              })}${getConfig("scope").domain === "car4me.bg" ? "" : " *"}`
                    }
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.power",
                        defaultMessage: "Leistung",
                    })}
                    value={
                        vehicle.powerPS
                            ? ` ${intl.formatMessage(
                                  {
                                      id: "leaseme.powerValue",
                                      defaultMessage: "{powerPS} PS",
                                  },
                                  {
                                      powerPS: vehicle.powerPS,
                                  },
                              )}`
                            : null
                    }
                />
                {getConfig("scope").domain === "car4me.bg" && (
                    <>
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.driveType",
                                defaultMessage: "Antrieb",
                            })}
                            value={vehicle.driveType?.fmt ?? "-"}
                        />
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.seats",
                                defaultMessage: "Sitze",
                            })}
                            value={vehicle.seats?.toString() ?? "-"}
                        />
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.engineDisplacement",
                                defaultMessage: "Hubraum",
                            })}
                            value={vehicle.cylinderCapacity?.fmt ?? "-"}
                        />
                    </>
                )}

                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.fuelConsumption",
                        defaultMessage: "Komb. Kraftstoffverbr.",
                    })}
                    value={vehicle.fuel?.consumption?.fmt ?? "-"}
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.fuelType",
                        defaultMessage: "Kraftstoffart",
                    })}
                    value={vehicle.fuel?.text ?? "-"}
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage(
                        {
                            id: "leaseme.pdf.emission.label",
                            defaultMessage: "<label>CO</label><sublabel>2</sublabel><label>-Emission</label>",
                        },
                        {
                            // eslint-disable-next-line react/display-name
                            label: (chunks: any) => <Text style={vehicleDataStyles.emissionLabel}>{chunks}</Text>,
                            // eslint-disable-next-line react/display-name
                            sublabel: (chunks: any) => <Text style={vehicleDataStyles.emissionSubLabel}>{chunks}</Text>,
                        },
                    )}
                    value={vehicle.fuel?.co2?.fmt ?? "-"}
                />
                <VehicleDataEntryComponent
                    title={intl.formatMessage({
                        id: "leaseme.offerRefference",
                        defaultMessage: "Bestandsnr.",
                    })}
                    value={vehicle.offerReference || "-"}
                />
                {getConfig("scope").domain === "car4me.bg" && (
                    <>
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.doors",
                                defaultMessage: "Türen",
                            })}
                            value={vehicle.doors?.toString() ?? "-"}
                        />
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.body",
                                defaultMessage: "Karosserie",
                            })}
                            value={vehicle.bodyType?.fmt ?? "-"}
                        />
                        <VehicleDataEntryComponent
                            title={intl.formatMessage({
                                id: "leaseme.transmission",
                                defaultMessage: "Getriebeart",
                            })}
                            value={vehicle.transmission?.fmt ?? "-"}
                        />
                    </>
                )}
            </View>
        </View>
    );
};

export default VehicleDataComponent;
