import { StyleSheet } from "@react-pdf/renderer";

export const headerStyles = StyleSheet.create({
    root: {
        top: "0pt",
        right: "0pt",
        height: "50pt",
        width: "100%",
        marginBottom: "1.5cm",
    },
    logoImage: {
        position: "absolute",
        top: "1.65cm",
        right: "2.62cm",
        height: "0.75cm",
        maxWidth: "6cm",
    },
});
