import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const dealerInfoStyles = StyleSheet.create({
    root: {
        flexDirection: "column",
        paddingTop: "1cm",
        margin: "0 2.62cm",
    },
    wrapper: {
        width: "50%",
        flexDirection: "column",
    },
    title: {
        fontSize: "9pt",
        fontWeight: "bold",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        paddingBottom: "12pt",
        paddingTop: "8pt",
        textTransform: "uppercase",
    },
    headline: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontWeight: "bold",
        fontSize: "9pt",
    },
    subTitle: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontSize: "9pt",
        marginTop: "4pt",
    },
});
