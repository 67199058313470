import { Document, Font, Page } from "@react-pdf/renderer";
import HeaderAT from "@src/AT/vehicleDetail/pdf/header/Header";
import HeaderBG from "@src/BG/vehicleDetail/pdf/header/Header";
import { getConfig } from "@src/config";
import { GQLFinanceInformation, GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import HeaderHU from "@src/HU/vehicleDetail/pdf/header/Header";
import HeaderRO from "@src/RO/vehicleDetail/pdf/header/Header";
import Fonts from "@src/shared/assets/fonts/fonts";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";
import DealerInfoComponent from "@src/shared/finCalcLightbox/pdf/DealerInfoComponent";
import FinCalcComponent from "@src/shared/finCalcLightbox/pdf/FinCalcComponent";
import LeaseMeEquipmentComponent from "@src/shared/vehicleDetail/pdf/equipment/EquipmentComponent";
import FooterComponent from "@src/shared/vehicleDetail/pdf/footer/FooterComponent";
import FootnoteComponent from "@src/shared/vehicleDetail/pdf/footnote/FootnoteComponent";
import QRCodeComponent from "@src/shared/vehicleDetail/pdf/qrcode/QRCodeComponent";
import TitleComponent from "@src/shared/vehicleDetail/pdf/title/TitleComponent";
import HeaderSI from "@src/SI/vehicleDetail/pdf/header/Header";
import * as React from "react";
import { useIntl } from "react-intl";

import FinancialDetailComponent from "./financialdetail/FinancialDetailComponent";
import { leasemePdfStyles } from "./LeaseMePdfStyles";
import VehicleDataComponent from "./vehicledata/VehicleDataComponent";

interface IProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    type: GQLFinanceType;
    filter: IFilter;
    financeInformation?: GQLFinanceInformation;
    qrCode?: string;
}

Font.register({
    family: "MarkW1G",
    fonts: [
        { src: Fonts.MarkW1GHeavy, fontWeight: 700 },
        { src: Fonts.MarkW1GMedium, fontWeight: 500 },
        { src: Fonts.MarkW1GRegular, fontWeight: "normal" },
    ],
});

const Header: React.FC = () => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <HeaderBG />;
        case "car4me.ro":
            return <HeaderRO />;
        case "car4me.si":
            return <HeaderSI />;
        case "car4me.porschefinance.hu":
            return <HeaderHU />;
        default:
            return <HeaderAT />;
    }
};

const LeaseMePdf: React.FunctionComponent<IProps> = ({ vehicle, type, filter, financeInformation, qrCode }) => {
    const intl = useIntl();

    return (
        <Document
            title={`${vehicle.modelGroup} ${intl.formatMessage({
                id: "leaseme.vehicleDetails",
                defaultMessage: "Fahrzeugdetails",
            })}`}
            producer={intl.formatMessage({
                id: "leaseme.pdf.producer",
                defaultMessage: "Porsche Bank",
            })}
        >
            <Page style={leasemePdfStyles.page} size="A4" wrap>
                <Header />
                <TitleComponent title={vehicle.model} />
                <FinancialDetailComponent vehicle={vehicle} type={type} filter={filter} />
                <VehicleDataComponent vehicle={vehicle} />
                {vehicle.nwSeriesEquipment && vehicle.nwSeriesEquipment.length > 0 ? (
                    <LeaseMeEquipmentComponent equipment={vehicle.nwSeriesEquipment} />
                ) : (
                    vehicle.equipment && vehicle.equipment.length > 0 && <LeaseMeEquipmentComponent equipment={vehicle.equipment} />
                )}
                {financeInformation && <FinCalcComponent vehicle={vehicle} financeInformation={financeInformation} />}
                <FootnoteComponent />
                {qrCode && <QRCodeComponent qrCode={qrCode} />}
                <FooterComponent />
                {vehicle.dealer && <DealerInfoComponent dealer={vehicle.dealer} />}
            </Page>
        </Document>
    );
};

export default LeaseMePdf;
