import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

import AccentLine from "../accent/AccentLine";
import { titleComponentStyles } from "./TitleComponentStyles";

interface ITitleProps {
    title?: string | null;
}

const TitleComponent: React.FunctionComponent<ITitleProps> = ({ title = "" }) => {
    const vehicleTitle: string = title + "*";
    return (
        <View style={titleComponentStyles.viewRoot}>
            <AccentLine />
            <Text style={titleComponentStyles.title}>{vehicleTitle}</Text>
        </View>
    );
};

export default TitleComponent;
