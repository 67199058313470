import { Image, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { qrCodeStyles } from "./QRCodeStyles";

interface IQRCodeProps {
    qrCode: string;
}

const QRCodeComponent: React.FunctionComponent<IQRCodeProps> = ({ qrCode }) => {
    return (
        <View style={qrCodeStyles.root} fixed>
            <View style={qrCodeStyles.qrCodeImage}>
                <Image src={qrCode} />
            </View>
            <Text style={qrCodeStyles.text}>
                <FormattedMessage
                    id="leaseme.qrCode.qrCodeDescription"
                    defaultMessage={"Scannen Sie den QR-Code und rufen Sie Ihr Fahrzeug bei LEASE ME erneut auf."}
                />
            </Text>
        </View>
    );
};

export default QRCodeComponent;
