import { GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import * as sc from "./PriceInfoValues.sc";

interface IPriceInfoValuesProps {
    visibleTab: GQLFinanceType;
    data: GQLVehicleDetailQuery["vehicle"];
}

const PriceInfoValues: React.FunctionComponent<IPriceInfoValuesProps> = ({ visibleTab, data }) => {
    const { filter } = useSearchFilter();
    const intl = useIntl();
    const priceInfo = data?.finCalcData;

    const rate = priceInfo?.rate;
    const downPayment = priceInfo?.downPayment ?? 0;
    const residualValue = priceInfo?.residualValue ?? 0;

    return (
        <>
            <sc.ContentValues>
                <sc.ResidualValue>
                    <sc.ResidualValueTitle>
                        {visibleTab === GQLFinanceType.leasing ? (
                            <FormattedMessage id="leaseme.priceInfo.residualValue.leasing" defaultMessage="Restwert-Leasing" />
                        ) : (
                            <FormattedMessage id="leaseme.priceInfo.residualValue.credit" defaultMessage="Restwert-Kredit" />
                        )}
                    </sc.ResidualValueTitle>
                    <sc.AmountTextContainer>
                        <sc.Amount>
                            <FormattedCurrency value={rate} maximumFractionDigits={0} minimumFractionDigits={0} /> **
                        </sc.Amount>
                        <sc.ResidualValueText>
                            <FormattedMessage id="leaseme.perMonth" defaultMessage="pro Monat" />
                        </sc.ResidualValueText>
                    </sc.AmountTextContainer>
                </sc.ResidualValue>
                <sc.Values>
                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedMessage
                                id="leaseme.runtime.value"
                                defaultMessage="{financeRuntime} Monate"
                                values={{
                                    financeRuntime: intl.formatNumber(filter.financeRuntime),
                                }}
                            />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                        </sc.ValueContent>
                    </sc.Value>

                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedNumber value={residualValue !== 0 ? residualValue : filter.remainingCredit ?? 0} />
                            <FormattedMessage id="leaseme.percent" defaultMessage="%" />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.residualCredit.label" defaultMessage="Restwert-Kredit" />
                        </sc.ValueContent>
                    </sc.Value>

                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedCurrency value={downPayment !== 0 ? downPayment : filter.downPayment} />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                        </sc.ValueContent>
                    </sc.Value>
                </sc.Values>
            </sc.ContentValues>
        </>
    );
};

export default PriceInfoValues;
