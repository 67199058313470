import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const accentLineStyles = StyleSheet.create({
    accentLine: {
        width: "1.31cm",
        height: "1.5pt",
        backgroundColor: theme.colors.darkRed,
    },
});
