import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const titleComponentStyles = StyleSheet.create({
    viewRoot: {
        margin: "0 2.62cm",
    },
    title: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontSize: "16pt",
        fontWeight: "bold",
        marginTop: "11pt",
    },
});
