import SharedHeader from "@src/shared/vehicleDetail/pdf/header/Header";
import * as React from "react";

import Logo_bg from "../../../../../static/bg/porschebank_logo_bg_blue.png";

const Header: React.FC = () => {
    return <SharedHeader logo={Logo_bg} />;
};

export default Header;
