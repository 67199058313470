import { Text, View } from "@react-pdf/renderer";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { equipmentSort } from "@src/shared/utils/equipmentSort";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import AccentLine from "../accent/AccentLine";
import { equipmentStyles } from "./EquipmentStyles";

interface IEquipmentProps {
    equipment: NonNullable<GQLVehicleDetailQuery["vehicle"]["equipment"]>;
}

const LeaseMeEquipmentComponent: React.FunctionComponent<IEquipmentProps> = ({ equipment }) => {
    if (equipment.length == 0) {
        return null;
    }
    equipment = [...equipment].sort(equipmentSort);

    const leftEquipmentItems = [];
    // adding all elements with even index (0, 2, 4, ...)
    for (let i = 0; i < equipment.length; i += 2) {
        leftEquipmentItems.push(
            <View style={equipmentStyles.equipmentContainer} key={i}>
                <Text style={equipmentStyles.point}>&#x2022;</Text>
                <Text style={equipmentStyles.equipment}>{equipment[i].text}</Text>
            </View>,
        );
    }
    const rightEquipmentItems = [];
    // adding all elements with odd index (1, 3, 5, ...)
    if (equipment.length >= 1) {
        for (let i = 1; i < equipment.length; i += 2) {
            rightEquipmentItems.push(
                <View style={equipmentStyles.equipmentContainer} key={i}>
                    <Text style={equipmentStyles.point}>&#x2022;</Text>
                    <Text style={equipmentStyles.equipment}>{equipment[i].text}</Text>
                </View>,
            );
        }
    }

    return (
        <View style={equipmentStyles.root} break>
            <View>
                <AccentLine />
                <Text style={equipmentStyles.title}>
                    <FormattedMessage id="leaseme.equipment" defaultMessage="Ausstattung" />
                </Text>
            </View>
            <View style={equipmentStyles.content}>
                <View style={equipmentStyles.contentLeft}>{leftEquipmentItems}</View>
                <View style={equipmentStyles.contentRight}>{rightEquipmentItems}</View>
            </View>
        </View>
    );
};

export default LeaseMeEquipmentComponent;
