import { Text, View } from "@react-pdf/renderer";
import { GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import { getFormattedCurrency } from "@src/shared/utils/getFormattedCurrency";
import * as React from "react";
import { useIntl } from "react-intl";

import { financialDetailTitleStyles } from "./FinancialDetailTitleStyles";

interface IDetailsTitleProps {
    priceInfo?: GQLPriceInfoFragment;
}

const FinancialDetailTitleComponent: React.FunctionComponent<IDetailsTitleProps> = ({ priceInfo }) => {
    const intl = useIntl();

    const rate = priceInfo?.finCalcData?.rate;

    // if-Guard used because Lingohub does not know select masking
    const subTitle = intl.formatMessage({
        id: "leaseme.priceInfo.residualValue.leasing",
        defaultMessage: "Restwert-Leasing",
    });

    const mainTitle = `${intl.formatMessage(
        {
            id: "leaseme.pdf.mainTitle.leasing",
            defaultMessage: "Leasingentgelt {rate} mtl.",
        },
        {
            rate: getFormattedCurrency({ value: rate || 0, maximumFractionDigits: 2 }, intl),
        },
    )} *`;

    return (
        <View style={financialDetailTitleStyles.root}>
            <View style={financialDetailTitleStyles.container}>
                <Text style={financialDetailTitleStyles.rateHeadline}>{mainTitle}</Text>
                <Text style={financialDetailTitleStyles.subTitle}>{subTitle}</Text>
            </View>
        </View>
    );
};

export default FinancialDetailTitleComponent;
