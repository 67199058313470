import { Image, View } from "@react-pdf/renderer";
import DisclaimerAT from "@src/AT/vehicleDetail/pdf/financialdetail/Disclaimer";
import FinancialDetailDataComponentAT from "@src/AT/vehicleDetail/pdf/financialdetail/FinancialDetailDataComponent";
import FinancialDetailTitleComponentAT from "@src/AT/vehicleDetail/pdf/financialdetail/FinancialDetailTitleComponent";
import DisclaimerBG from "@src/BG/vehicleDetail/pdf/financialdetail/Disclaimer";
import FinancialDetailDataComponentBG from "@src/BG/vehicleDetail/pdf/financialdetail/FinancialDetailDataComponent";
import FinancialDetailTitleComponentBG from "@src/BG/vehicleDetail/pdf/financialdetail/FinancialDetailTitleComponent";
import { getConfig } from "@src/config";
import { GQLFinanceType, GQLPriceInfoFragment, GQLVehicleDetailQuery, GQLVehicleType } from "@src/graphql.apollo.generated";
import DisclaimerHU from "@src/HU/vehicleDetail/pdf/financialdetail/Disclaimer";
import FinancialDetailDataComponentHU from "@src/HU/vehicleDetail/pdf/financialdetail/FinancialDetailDataComponent";
import FinancialDetailTitleComponentHU from "@src/HU/vehicleDetail/pdf/financialdetail/FinancialDetailTitleComponent";
import FinancialDetailDataComponentRO from "@src/RO/vehicleDetail/pdf/financialdetail/FinancialDetailDataComponent";
import FinancialDetailTitleComponentRO from "@src/RO/vehicleDetail/pdf/financialdetail/FinancialDetailTitleComponent";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";
import DisclaimerSI from "@src/SI/vehicleDetail/pdf/financialdetail/Disclaimer";
import FinancialDetailDataComponentSI from "@src/SI/vehicleDetail/pdf/financialdetail/FinancialDetailDataComponent";
import FinancialDetailTitleComponentSI from "@src/SI/vehicleDetail/pdf/financialdetail/FinancialDetailTitleComponent";
import * as React from "react";

import { financialDetailComponentStyles } from "./FinancialDetailComponentStyles";

interface FinancialDetailProps {
    type: GQLFinanceType;
    vehicleType?: GQLVehicleType;
    priceInfo?: GQLPriceInfoFragment;
    filter: IFilter;
}

interface DisclaimerProps {
    priceInfo?: GQLPriceInfoFragment;
}

interface IDetailsProps extends FinancialDetailProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    filter: IFilter;
}

const FinancialDetailContentComponent: React.FC<FinancialDetailProps> = ({ type, priceInfo, filter, vehicleType }) => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return (
                <>
                    <FinancialDetailTitleComponentBG priceInfo={priceInfo} />
                    <FinancialDetailDataComponentBG priceInfo={priceInfo} filter={filter} />
                </>
            );
        case "car4me.ro":
            return (
                <>
                    <FinancialDetailTitleComponentRO type={type} priceInfo={priceInfo} />
                    <FinancialDetailDataComponentRO type={type} priceInfo={priceInfo} filter={filter} />
                </>
            );
        case "car4me.si":
            return (
                <>
                    <FinancialDetailTitleComponentSI type={type} priceInfo={priceInfo} />
                    <FinancialDetailDataComponentSI type={type} currentPriceInfo={priceInfo} filter={filter} />
                </>
            );
        case "car4me.porschefinance.hu":
            return (
                <>
                    <FinancialDetailTitleComponentHU type={type} priceInfo={priceInfo} />
                    <FinancialDetailDataComponentHU type={type} priceInfo={priceInfo} filter={filter} />
                </>
            );
        default:
            return (
                <>
                    <FinancialDetailTitleComponentAT type={type} priceInfo={priceInfo} vehicleType={vehicleType} />
                    <FinancialDetailDataComponentAT type={type} priceInfo={priceInfo} filter={filter} />
                </>
            );
    }
};

const Disclaimer: React.FC<DisclaimerProps> = ({ priceInfo }) => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <DisclaimerBG priceInfo={priceInfo} />;
        case "car4me.ro":
            return <></>;
        case "car4me.si":
            return <DisclaimerSI priceInfo={priceInfo} />;
        case "car4me.porschefinance.hu":
            return <DisclaimerHU priceInfo={priceInfo} />;
        default:
            return <DisclaimerAT priceInfo={priceInfo} />;
    }
};

const FinancialDetailComponent: React.FunctionComponent<IDetailsProps> = ({ vehicle, type, filter }) => {
    const images = vehicle.images;

    const priceInfo = { finCalcData: vehicle.finCalcData };

    return (
        <View style={financialDetailComponentStyles.viewRoot}>
            <View style={financialDetailComponentStyles.root}>
                <View style={financialDetailComponentStyles.imageContainer}>
                    {images && images.length > 0 && images[0].uri && <Image style={financialDetailComponentStyles.image} src={images[0].uri} />}
                </View>
                <View style={financialDetailComponentStyles.detailsContainer}>
                    <FinancialDetailContentComponent type={type} priceInfo={priceInfo} filter={filter} vehicleType={vehicle.type ?? undefined} />
                </View>
            </View>
            <Disclaimer priceInfo={priceInfo} />
        </View>
    );
};

export default FinancialDetailComponent;
