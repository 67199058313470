import SharedHeader from "@src/shared/vehicleDetail/pdf/header/Header";
import * as React from "react";

import Logo_hu from "../../../../../static/hu/Porsche-Hungary-Logo-blue.png";

const Header: React.FC = () => {
    return <SharedHeader logo={Logo_hu} />;
};

export default Header;
