import { StyleSheet } from "@react-pdf/renderer";

export const financialDetailComponentStyles = StyleSheet.create({
    viewRoot: {
        margin: "0 2.62cm",
    },
    root: {
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
        marginTop: "8pt",
    },
    imageContainer: {
        width: "50%",
    },
    image: {
        height: "auto",
        width: "100%",
        objectFit: "cover",
    },
    detailsContainer: {
        width: "50%",
        flexDirection: "column",
    },
});
