import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const qrCodeStyles = StyleSheet.create({
    root: {
        position: "absolute",
        bottom: "1.27cm",
        left: "2.62cm",
        height: "45pt",
        flexDirection: "row",
    },
    qrCodeImage: {
        width: "45pt",
        height: "45pt",
        marginRight: "8pt",
    },
    text: {
        fontSize: "8pt",
        fontWeight: "bold",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        width: "38%",
        alignSelf: "center",
    },
});
