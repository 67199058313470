import { Text, View } from "@react-pdf/renderer";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import AccentLine from "@src/shared/vehicleDetail/pdf/accent/AccentLine";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { dealerInfoStyles } from "./DealerInfoStyles";

interface IDataProps {
    dealer: GQLVehicleDetailQuery["vehicle"]["dealer"];
}

const DealerInfoComponent: React.FunctionComponent<IDataProps> = ({ dealer }) => {
    const intl = useIntl();

    return (
        dealer && (
            <View style={dealerInfoStyles.root}>
                <View>
                    <AccentLine />
                    <Text style={dealerInfoStyles.title}>
                        <FormattedMessage id="leaseme.dealerInformation" defaultMessage="Händlerinformation" />
                    </Text>
                </View>
                <View style={dealerInfoStyles.wrapper}>
                    <Text style={dealerInfoStyles.headline}>{dealer.name ?? "-"}</Text>
                    <Text style={dealerInfoStyles.subTitle}>{dealer.street ?? "-"}</Text>
                    {dealer.zip && dealer.city && (
                        <Text style={dealerInfoStyles.subTitle}>
                            {dealer.zip ?? ""} {dealer.city}
                        </Text>
                    )}
                    <Text style={dealerInfoStyles.subTitle}>{`${intl.formatMessage({
                        id: "leaseme.phone.label",
                        defaultMessage: "Tel.:",
                    })}${dealer.telephone ?? "-"}`}</Text>
                </View>
            </View>
        )
    );
};

export default DealerInfoComponent;
