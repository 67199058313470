import { Text, View } from "@react-pdf/renderer";
import { GQLFinanceType, GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import { getFormattedCurrency } from "@src/shared/utils/getFormattedCurrency";
import * as React from "react";
import { useIntl } from "react-intl";

import { financialDetailTitleStyles } from "./FinancialDetailTitleStyles";

interface IDetailsTitleProps {
    type: GQLFinanceType;
    priceInfo?: GQLPriceInfoFragment;
}

const FinancialDetailTitleComponent: React.FunctionComponent<IDetailsTitleProps> = ({ type, priceInfo }) => {
    const intl = useIntl();
    const rate = priceInfo?.finCalcData?.rate;

    // if-Guard used because Lingohub does not know select masking
    const subTitle =
        type === GQLFinanceType.leasing
            ? intl.formatMessage({
                  id: "leaseme.priceInfo.residualValue.leasing",
                  defaultMessage: "Restwert-Leasing",
              })
            : intl.formatMessage({
                  id: "leaseme.priceInfo.residualValue.credit",
                  defaultMessage: "Restwert-Kredit",
              });

    const mainTitle =
        type === GQLFinanceType.leasing
            ? `${intl.formatMessage(
                  {
                      id: "leaseme.pdf.mainTitle.leasing",
                      defaultMessage: "Leasingentgelt {rate} mtl.",
                  },
                  {
                      rate: getFormattedCurrency({ value: rate || 0, maximumFractionDigits: 0 }, intl),
                  },
              )} **`
            : `${intl.formatMessage(
                  {
                      id: "leaseme.pdf.mainTitle.credit",
                      defaultMessage: "Kreditrate {rate} mtl.",
                  },
                  {
                      rate: getFormattedCurrency({ value: rate || 0, maximumFractionDigits: 0 }, intl),
                  },
              )} **`;
    return (
        <View style={financialDetailTitleStyles.root}>
            <View style={financialDetailTitleStyles.container}>
                <Text style={financialDetailTitleStyles.rateHeadline}>{mainTitle}</Text>
                <Text style={financialDetailTitleStyles.subTitle}>{subTitle}</Text>
            </View>
        </View>
    );
};

export default FinancialDetailTitleComponent;
