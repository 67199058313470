import { Text, View } from "@react-pdf/renderer";
import { GQLFinanceInformation, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import AccentLine from "@src/shared/vehicleDetail/pdf/accent/AccentLine";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import FinCalcItem from "./FinCalcItem";
import { finCalcStyles } from "./FinCalcStyles";

interface IDataProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    financeInformation: GQLFinanceInformation;
}

const FinCalcComponent: React.FunctionComponent<IDataProps> = ({ financeInformation }) => {
    return (
        <View style={finCalcStyles.root} break>
            <View>
                <AccentLine />
                <Text style={finCalcStyles.title}>
                    <FormattedMessage id="leaseme.finCalc.financing" defaultMessage="Finanzierung über die Porsche Bank" />
                </Text>
            </View>
            {financeInformation.groups.map((group) => {
                return (
                    <View key={group.label}>
                        <Text style={finCalcStyles.groupTitle}>{group.label}</Text>
                        {group.parameters.map((parameter) => {
                            if (parameter.id === "ResidualValue") return;
                            return <FinCalcItem item={parameter} key={parameter.id} />;
                        })}
                    </View>
                );
            })}
        </View>
    );
};

export default FinCalcComponent;
