import styled from "styled-components";

export const ResidualValue = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 20px 0;
    display: flex;
    padding: 0 20px;
    white-space: nowrap;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: inline-block;
        padding: 0;
        margin-bottom: 30px;
    }

    ${({ theme }) => theme.breakpoints.lg2.mediaquery} {
        width: 30%;
    }
`;

export const ResidualValueTitle = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    white-space: normal;
`;

export const Amount = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 32px;
        margin: 10px 0;
    }
`;

export const AmountTextContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 5px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        margin: 0;
    }
`;

export const Values = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    padding: 0 20px;
    flex-wrap: wrap;
    gap: 15px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        margin-bottom: 30px;
        gap: 10px;
    }
`;

export const ResidualValueText = styled(ResidualValueTitle)`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        margin: 0;
    }
`;

export const ContentValues = styled.div`
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: flex;
        flex-direction: column;
    }
`;

export const ValueHeader = styled.h3`
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
    font-weight: 700;
    align-self: end;
`;

export const ValueContent = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    white-space: normal;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    align-self: start;
    min-height: 48px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        white-space: nowrap;
    }
`;

export const Value = styled.div`
    margin: 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 150px;
        margin-right: 5px;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        margin: 0;
    }
`;
